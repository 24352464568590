import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/understanding-cloneelement-isvalidelement-and-render-props",
  "date": "2021-11-06",
  "title": "Understanding cloneElement, isValidElement and Render Props",
  "author": "admin",
  "tags": ["development", "javascript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "React provides several APIs for transforming elements: cloneElement, isValidElement."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this story, we’ll have a look at using these to compose React components, how they work and when to use them. We then look at how the render props pattern can help transform elements the way we need them.`}</p>
    <h2>{`Add new props to an existing component using cloneElement`}</h2>
    <p>{`Let's say, for example, you want to add a new class and an on-click handler to the children of an existing component.`}</p>
    <p>{`One of the ways we can add new props to the `}<inlineCode parentName="p">{`children`}</inlineCode>{` is by using `}<inlineCode parentName="p">{`React.cloneElement()`}</inlineCode>{`.`}</p>
    <p>{`Here's how we can implement it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`function Buttons({ children }) {
  const newProps = {
    className: "highlight",
    onClick: () => console.log("Clicked!")
  };

  return (
    <>
      {children.map((child, index) =>
        React.cloneElement(child, { ...newProps, key: index })
      )}
    </>
  );
}
`}</code></pre>
    <p>{`Using `}<inlineCode parentName="p">{`React.cloneElement()`}</inlineCode>{` does the following:`}</p>
    <ol>
      <li parentName="ol">{`Original element's props are shallow-merged with new props`}</li>
      <li parentName="ol">{`New children replace existing children`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`key`}</inlineCode>{` and `}<inlineCode parentName="li">{`ref`}</inlineCode>{` from the original element are preserved`}</li>
    </ol>
    <p>{`The syntax looks as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`React.cloneElement(
  element, // A new React element using element as the starting point
  [config], // all new props + key or ref
  [...children]
);
`}</code></pre>
    <h2>{`Check for valid React elements using isValidElement`}</h2>
    <p>{`This function returns `}<inlineCode parentName="p">{`true`}</inlineCode>{` if an element is a valid React Element and React can render it. Returns `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`React.isValidElement(object);
`}</code></pre>
    <p>{`Here’s an example of modifying the elements from the previous example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Buttons({ children }) {
  const newProps = {
    className: 'highlight',
    onClick: () => console.log('Clicked!'),
  };

  return (
    <>
      {children.map(
        (child, index) =>
          React.isValidElement(child) &&
          React.cloneElement(child, { ...newProps, key: index })
      )}
    </>
  );
}
`}</code></pre>
    <h2>{`Share code between components using Render Props`}</h2>
    <p>{`With render props, we could render anything we wanted, just like the children prop method. But we will also be able to pass props to whatever is being rendered. The main purpose of Render Props is being able to communicate with any rendered component without having to couple implementation details.`}</p>
    <p>{`Let's change the above example to use RenderProps:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Buttons(props) {
  return props.render();
}

export default function App() {
  return (
      <Buttons
        render={() => (
          <>
            <button>Hello</button>
            <button>World</button>
          </>
        )}
      />
    </div>
  );
}
`}</code></pre>
    <h2>{`Summary`}</h2>
    <p>{`You can use `}<inlineCode parentName="p">{`cloneElement`}</inlineCode>{` to inject values into your child components when the parent-child relationship implies a shared API.`}</p>
    <p>{`If you desire freedom when it comes to deciding the way a part of your component is rendered Render props would be the ideal choice. You could achieve the same effect using `}<inlineCode parentName="p">{`cloneElement`}</inlineCode>{`, but `}<inlineCode parentName="p">{`cloneElement`}</inlineCode>{` forces you to define and use a fully-fledged component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      